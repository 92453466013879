@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  font-family: Helvetica;
}

.team .slick-slide > div {
  margin: 0 40px;
}

.team .slick-slide > div:nth-child(1){
  margin-left: 0;
}

.team .slick-dots li button::before{
color: white;
font-size: 12px;
}

.team .slick-dots li.slick-active button:before {
color: #D79E8E;
}

.team .slick-list {
margin-right: -40px;
}

.cinput {
  padding: 8px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 2%;
}

.ctextarea{
  padding: 8px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 15vh;
  margin-top: 5px;
  margin-right: 2%;
  resize: none;
}
.cSelect{
  padding: 8px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 2%;
}
.atextarea{
  padding: 8px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 2%;
  resize: none;
}

.cbutton{
  background-color: #D79E8E;
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 7px;
  color: white;
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #EF5B2C !important; 
  list-style: none;
  font-weight: 500;
}

.ant-tabs-tab-btn:hover{
  color: #EF5B2C;
  }
  
  .ant-tabs-ink-bar {
  height: 5px;
  background: none !important;
  }

  .slick-prev {
    visibility: hidden !important;
  }
  .slick-next {
    visibility: hidden !important;
  }

  .icon_1 {
    animation: icon_1 1s infinite;
  }
  
  @keyframes icon_1 {
    0%{
      opacity: 1;
    }
    50%{
      opacity: 0.5;
    }
    100%{
      opacity: 0.5;
    }
  }
  
  .icon_2 {
    animation: icon_2 1s infinite;
  }
  
  @keyframes icon_2 {
    0%{
      opacity: 0.5;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0.5;
    }
  }
  
  .icon_3 {
    animation: icon_3 1s infinite;
  }
  
  @keyframes icon_3 {
    0%{
      opacity: 0.5;
    }
    50%{
      opacity: 0.5;
    }
    100%{
      opacity: 1;
    }
  }